<template>
  <header class="fixed top">
    <div class="header-navbar">
      <!-- Left -->
      <div class="hn-left">
        <slot name="header-left">
          <a class="btn-link" href="javascript:void(0);" @click="goBackFromHeader">
            <i class="icon icon-arrow-left"></i>
          </a>
        </slot>
      </div>

      <!-- Right -->
      <div class="hn-right">
        <slot name="header-right"></slot>
      </div>

      <!-- Center -->
      <div class="hn-middle">
        <h1 class="title">
          <slot name="header-middle">神腦生活</slot>
        </h1>
      </div>
    </div>
  </header>
</template>

<script>
import { goBackFromHeader } from '@/helpers/navigation-goback-where';

const methods = {
  goBackFromHeader,
};

export default {
  name: 'header-cart',
  methods,
};
</script>
