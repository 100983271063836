import { callMutation, commitMutation, commitMutations, renderState } from '@/helpers/vuex';
import { cocoBack, getGoBackWhere } from '@/helpers/is-from-app';
import { getCookie } from '@/helpers/cookie';

const online = process.env.VUE_APP_ONLINE;

const delivery = renderState('DeliveryInfo', 'delivery');

export const isMainDelivery = () => Boolean(['1', '2'].indexOf(delivery) > -1);

/** switch header left
 * @param {String} thisComponent
 * thisComponent: 'mobileCartChoice', 'mobileCartMain' */
const switchHeaderFlag = (thisComponent) => {
  switch (getGoBackWhere()) {
    case 1:
      window.location.href = `${online}/goBackAPP`;
      break;
    case 2:
      window.location.href = `${cocoBack}/`;
      break;
    case 3:
      if (thisComponent === 'mobileCartChoice') {
        window.location.href = getCookie('redirect_mart_link');
        return;
      }
      window.history.back();
      break;
    default:
      if (thisComponent === 'mobileCartChoice') {
        window.location.href = `${online}`;
        return;
      }
      window.location.href = `${process.env.VUE_APP_CART}`;
  }
};

/** switch cartMain bottom '繼續購物' */
const switchBottomFlag = () => {
  switch (getGoBackWhere()) {
    case 1:
      window.location.href = `${online}/goBackAPP`;
      break;
    case 2:
      window.location.href = `${cocoBack}/`;
      break;
    case 3:
      window.location.href = getCookie('redirect_mart_link');
      break;
    default:
      window.location.href = `${online}`;
  }
};

const atStep1GoBack = () => {
  commitMutation('DynamicComponents', 'deliveryMethod', '');
  commitMutation('DeliveryInfo', 'delivery', '');
  // 是否有 call 過 serviceCenter API & getStore API
  const isCalledStoreAPI = renderState('CityTownStores', 'isCalledStoreAPI');
  if (isCalledStoreAPI) {
    // 有呼叫過 storeAPI 即不 reset
    window.history.back();
    return;
  }
  // 避免 step1 資料殘存 ; 只有宅配與門取才要清除運送方式
  const isSaveReceipt = renderState('RecipientInfo', 'isSaveReceipt');
  // 有去過 step2 即清除 vuex 裡的門市資訊，待再進來時直接從 checkOut API 提供的門市 ID call 門市 API
  if (isSaveReceipt) {
    callMutation('RecipientInfo', 'RESET_STATE');
  }
  // if user has select store, then clear index value
  commitMutations('CityTownStores', {
    cityIndex: 0,
    townIndex: 0,
    storeIndex: 0,
  });
  window.history.back();
};

const atStep2GoBack = () => {
  /**
   * 來源1: 宅配 OR 門取的上一頁 -> Step1
   * 來源2: 電子票券 OR 其它的上一頁 -> cartMain
   * delivery:
   *  0 : 免配送
   *  1 : 宅配車
   *  2 : 門市取貨車
   * */
  if (isMainDelivery) {
    commitMutation('DynamicComponents', 'deliveryMethod', delivery === '1' ? 'step1-addressee' : 'step1-store');
  }
  // 是宅配或門市取貨則回 step1
  // 電子票券、預購型的電子票券及其他無須配送車則回 cartMain
  return window.history.back();
};
/** bottom-btn.vue from cartMain 下方「繼續購物」 */
export const goBackFromFlag = () => switchBottomFlag();

/** header-cart.vue */
export const goBackFromHeader = function () {
  const thisComponent = this.$router.history.current.name;
  const atRootPage = ['mobileCartChoice', 'mobileCartMain'].includes(thisComponent);
  // 情境: 從 cartChoice 或 cartMain 按返回
  if (atRootPage) {
    return switchHeaderFlag(thisComponent);
  }
  // 判斷從哪一個 step 頁按返回
  const atStep1 = ['mobileCartStep1'].includes(thisComponent);
  if (atStep1) {
    return atStep1GoBack();
  }
  const atStep2 = ['mobileCartStep2'].includes(thisComponent);
  if (atStep2) {
    return atStep2GoBack();
  }
  // 預設: 回上一頁
  return window.history.back();
};

/** step1-footer.vue */
export const goBackFromStep1 = () => atStep1GoBack();

/** bottom-toolbar.vue */
export const goBackFromStep2 = () => atStep2GoBack();
